// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha.5/css/bootstrap.css";
@import "node_modules/react-date-picker/style/index";

body {
  background-color: #E8E8E8;
  padding-top: 60px;
  @media screen and (max-width: 640px) {
    padding-top: 20px;
  }
}

select.form-control {
  -moz-appearance: none;
}

.react-date-field__calendar-icon {
  right: 15px;
}

/*
h1, h2, h3 {
  margin: 0 0 10px;
}
*/

#logo {
  background: url('http://clasi7icados.com/wp-content/themes/cachicha-v2/img/el-caribe/logo-full.png') no-repeat center left;
  height: 128px;
  @media screen and (max-width: 585px) {
    background-size: cover;
    width: 320px;
    height: 85px;
  }
}

.fixed {
  @media screen and (min-width: 576px) {
    position: fixed;
    top: 15px;
    width: 225px;
  }
  @media screen and (min-width: 768px) {
    position: fixed;
    top: 15px;
    width: 280px;
  }
  @media screen and (min-width: 992px) {
    position: fixed;
    top: 15px;
    width: 400px;
  }
  @media screen and (min-width: 1200px) {
    position: fixed;
    top: 15px;
    width: 490px;
  }
}

#wrapper {
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 40px 50px;
  @media screen and (max-width: 640px) {
    padding: 20px 15px 10px;
  }
}

.color-bars {
  margin-bottom: 20px;
  margin-top: -10px;
  .color-bar {
    width: 16.66%;
    height: 6px;
    display: inline-block;
    &.blue {
      background-color: #008da8;
    }
    &.lightblue {
      background-color: #3294d2;
    }
    &.green {
      background-color: #77b82a;
    }
    &.purple {
      background-color: #cf447b;
    }
    &.red {
      background-color: #ae321d;
    }
    &.orange {
      background-color: #ef8034;
    }
  }
}

.ad {
  max-width: 180px;
  margin: 0 auto;
}

#invoice {
  table thead tr:first-child th {
    color: white;
    background: #008da8;
  }
}

#preview {
  width: 100%;
  margin: 0 auto 15px;
  .ad {
    color: #666666;
    font-size: 15px;
    line-height: 17px;

    .description {
      -ms-word-break: break-all;
      word-break: break-all;

      /* Non standard for webkit */
      word-break: break-word;

      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    &.title_highlight .title {
      background-color: black;
      color: white;
    }

    &.yellow_highlight {
      background-color: #F9FF3A;
    }

    &.orange_text {
      color: #FF6600;
    }

    &.green_highlight {
      background-color: #179C02;
      color: white;
    }

    &.red_dot {
      background-color: #930008;
      border-radius: 180px;
      padding: 15px 0;
      height: 180px;
      text-align: center;
      color: white;
      .title {
        color: #F62A16;
        margin: 5px auto 0;
        width: 140px;
      }
      .description {
        width: 140px;
      }
    }
    &.red_circle {
      background: transparent url('http://clasi7icados.com/wp-content/themes/cachicha-v2/img/ad_feature_red_circle.png') no-repeat center center;
      height: 180px;
      padding: 10px 0 20px;
      text-align: center;
      .title {
        margin: 5px auto 0;
        width: 140px;
      }
      .description {
        width: 140px;
      }
    }
  }
}

.well {
  display: inline-block;
  margin-right: 10px;
}
